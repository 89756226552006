import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from "./material/material.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FirebaseAuth, FirebaseStorage, OrdinaryAlgorithm, OrdinaryQuery, OrdinaryComponent, FirebaseImage, QueryVhweb, QueryVhAPP } from 'private-angular-vhframeworks';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebappManageLibraryComponent } from './components/webapp-manage-library/webapp-manage-library.component';
import { WebappManageLibraryAddFolderComponent } from './components/webapp-manage-library/webapp-manage-library-add-folder/webapp-manage-library-add-folder.component';
import { WebappManageLibraryEditFolderComponent } from './components/webapp-manage-library/webapp-manage-library-edit-folder/webapp-manage-library-edit-folder.component';
import { WebappProductSalesCafeComponent } from './components/webapp-products-001/user/webapp-product-sales-cafe/webapp-product-sales-cafe.component';

@NgModule({
  declarations: [AppComponent, WebappManageLibraryComponent, WebappManageLibraryAddFolderComponent, WebappManageLibraryEditFolderComponent, WebappProductSalesCafeComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgbModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    FirebaseStorage,DatePipe , FirebaseImage, FirebaseAuth, OrdinaryAlgorithm, OrdinaryQuery, OrdinaryComponent, QueryVhweb, QueryVhAPP],
  bootstrap: [AppComponent],
})
export class AppModule { }
