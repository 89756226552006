import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { VhViethasWebAPPEndUser } from 'vhwebapp';

@Injectable({
  providedIn: 'root'
})
export class CheckInitGuard implements CanActivate {
  constructor(
    private router: Router,
    private vhViethasWebAPPEndUser: VhViethasWebAPPEndUser
    ) {}
//BrLuzTwMyFUspBz3UZObmBssGUl1
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):any {
		return this.vhViethasWebAPPEndUser.initializeProject("bvhsales", "firebase", 'research', '', 100522, 'lXjeqUwuBqScIkuf7sibcx2SF383')
					.then(() => {
						return true
					},err=> {
						//alert('Có lỗi vui lòng thử lại')
						return true
					})
	}
  
}
