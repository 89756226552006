import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VhViethasWebAPPAdmin } from 'vhwebapp';
import { WebappManageLibraryComponent } from '../webapp-manage-library.component';

@Component({
  selector: 'app-webapp-manage-library-add-folder',
  templateUrl: './webapp-manage-library-add-folder.component.html',
  styleUrls: ['./webapp-manage-library-add-folder.component.scss']
})
export class WebappManageLibraryAddFolderComponent implements OnInit {
  folderName: any = ""
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WebappManageLibraryAddFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vhViethasWebAPPAdmin: VhViethasWebAPPAdmin,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  addFolder() {
    if (this.folderName != "")
      this.vhViethasWebAPPAdmin.getFilenames_onViethas(this.data)
        .then((data: any) => {
          if (!data.find(item => item.name === this.folderName)) {
            this.vhViethasWebAPPAdmin.createFolder_onViethas(this.data, this.folderName)
              .then((data) => {
                console.log(data)
                this.dialogRef.close(data)
              })
          } else alert("Trùng tên, vui lòng đặt lại")

        }, error => {
          console.log('error', error);
        })
  }

}
