import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './admin/guard/auth.guard';
import { CheckInitGuard } from './user/checkInit/check-init.guard';
import path from './components/webapp-products-001/config/path/path.json'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./user/user-routing.module').then((m) => m.UserRoutingModule),
    canActivate: [CheckInitGuard]
  },
  {
    path: 'viethas',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'customer-care',
    loadChildren: () => import('./customer-care/customer-care.module').then((m) => m.CustomerCareModule),
  },
  {
    path: 'dealer',
    loadChildren: () => import('./dealer/dealer.module').then((m) => m.DealerModule),
  },
  {
    path: `${path.link}/:id_field`,
    loadChildren: () => import('./components/webapp-products-001/user/webapp-products-c001-user-module').then(m => m.WebappProductsC001UserModule),
    canActivate: [CheckInitGuard]
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
