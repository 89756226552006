import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VhViethasWebAPPAdmin } from 'vhwebapp';

@Component({
  selector: 'app-webapp-manage-library-edit-folder',
  templateUrl: './webapp-manage-library-edit-folder.component.html',
  styleUrls: ['./webapp-manage-library-edit-folder.component.scss']
})
export class WebappManageLibraryEditFolderComponent implements OnInit {
  folderName: any = ""
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WebappManageLibraryEditFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vhViethasWebAPPAdmin: VhViethasWebAPPAdmin,
  ) { }

  ngOnInit(): void {
    this.folderName = this.data.name
  }

  addFolder() {
    if (this.folderName != "") {
      this.vhViethasWebAPPAdmin.getFilenames_onViethas(this.data.path)
        .then((data: any) => {
         if(!data.find(item => item.name === this.data.name)) {
            this.vhViethasWebAPPAdmin.renameFolder_onViethas(this.data.path, this.data.name, this.folderName)
              .then((data) => {
                console.log(data)
                this.dialogRef.close(data)
              })
          } else alert("Trùng tên, vui lòng đặt lại")

        }, error => {
          console.log('error', error);
        })
    }

  }

}
