import { Component } from '@angular/core';
import { FirebaseStorage, FirebaseAuth } from "private-angular-vhframeworks";
import { RouterOutlet } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent {

  constructor(
    private firebaseStorage: FirebaseStorage,
    private firebaseAuth: FirebaseAuth,
  ) {
    this.firebaseStorage.initialize("vhviethasweb");
    this.firebaseAuth.initialize();
    this.setDefaultCurrency()
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  /**Set default currency */
  private setDefaultCurrency() {
    if (!localStorage.getItem("vhsales_currencyFormat")) {
      localStorage.setItem("vhsales_currencyFormat",
        JSON.stringify({ symbol: { text: "VND", value: 295 }, position: { text: '1 ' + 'VND', value: 1 }, digitDecimal: { text: '0', value: 0 }, digitGroup: { text: '123456', value: 0 } }));
    }
  }
}
