import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VhAlgorithm, VhViethasWebAPPAdmin } from 'vhwebapp';
import { WebappManageLibraryAddFolderComponent } from './webapp-manage-library-add-folder/webapp-manage-library-add-folder.component';
import { WebappManageLibraryEditFolderComponent } from './webapp-manage-library-edit-folder/webapp-manage-library-edit-folder.component';

@Component({
  selector: 'app-webapp-manage-library',
  templateUrl: './webapp-manage-library.component.html',
  styleUrls: ['./webapp-manage-library.component.scss']
})
export class WebappManageLibraryComponent implements OnInit {
  view: any = "icon"
  path: any = ""
  fileNames: any = []
  showFileNames: any = []
  activeRight: any = ""
  activeSelect: any = ""
  activeLeft: any = ""
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WebappManageLibraryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vhViethasWebAPPAdmin: VhViethasWebAPPAdmin,
    private vhAlgorithm: VhAlgorithm
  ) {
    let view = JSON.parse(localStorage.getItem("viewFilenames"))
    if (view) this.view = view
  }

  ngOnInit(): void {
    this.path = this.data
    this.getFilenames_onViethas(this.path)
  }

  ngAfterViewInit() {
    this.clickRightBackground()
  }


  //** hàm click chuột phải để tạo folder, tải file tại thư mục đó */
  clickRightBackground() {
    let ele = document.getElementById('element');
    let menu = document.getElementById('menu');
    ele.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    ele.addEventListener('contextmenu', (e) => {
      const rect = ele.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // Set the position for menu
      menu.style.top = `${y}px`;
      menu.style.left = `${x}px`;

      // Show the menu
      menu.classList.remove('hidden');
      this.resetActive()
    });
    ele.addEventListener('contextmenu', (e) => {
      document.addEventListener('click', documentClickHandler);
    });

    // Hide the menu when clicking outside of it
    const documentClickHandler = (e) => {
      const isClickedOutside = !menu.contains(e.target);
      if (isClickedOutside) {
        // Hide the menu
        menu.classList.add('hidden');
        this.resetActive()
        // Remove the event handler
        document.removeEventListener('click', documentClickHandler);
      }
    };
  }

  getFilenames_onViethas(path) {
    this.fileNames = []
    this.path = path
    this.vhViethasWebAPPAdmin.getFilenames_onViethas(path)
      .then((data) => {
        this.fileNames = data
        this.showFileNames = this.fileNames
        // this.activeSelect = item.name
      }, error => {
        console.log('error', error);
      })
  }
  openImage(href) {
    window.open(href, "_blank")
  }

  openAddFolder() {
    const dialogRef = this.dialog.open(WebappManageLibraryAddFolderComponent, {
      width: '35%',
      maxWidth: '100%',
      data: this.path,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getFilenames_onViethas(this.path)
    });
  }
  openEditFolder(item) {
    this.resetActive()
    let data = {
      path: this.path,
      name: item.name,  
    }
    const dialogRef = this.dialog.open(WebappManageLibraryEditFolderComponent, {
      width: '35%',
      maxWidth: '100%',
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getFilenames_onViethas(this.path)
    });
  }
  deleteFolder(item) {
    if (item.type == 0) {
      this.vhViethasWebAPPAdmin.deleteFolder_onViethas(this.path, item.name)
        .then(() => {
          this.getFilenames_onViethas(this.path)
        }, err => {
          alert("Có lỗi xảy ra")
          console.log(err)
        })
    } else {
      this.vhViethasWebAPPAdmin.deleteImage_onViethas(item.href)
        .then(() => {
          this.getFilenames_onViethas(this.path)
        }, err => {
          alert("Có lỗi xảy ra")
          console.log(err)
        })
    }
  }

  clickId() {
    document.getElementById('idUpload').click();
  }

  uploadImg(event) {
    console.log(event.target.files)
    let files: any = event.target.files
    
    let promise: any = []
    for (let i = 0; i < files.length; i++) promise.push(this.vhViethasWebAPPAdmin.getImageFromDesktop(files[i], this.path))
    Promise.all(promise).then((rsp: any) => {
      console.log(rsp,"rsp")
      for (let j = 0; j < rsp.length; j++) {
        if (rsp[j].vcode === 0) {
        
        } else if (rsp[j].vcode === 1) {
         
        } else if (rsp[j].vcode === 2) {

        } else if (rsp[j].vcode === 3) {

        }
      }
      this.getFilenames_onViethas(this.path)
    }, (err) => {
      console.error(err)
    }
    )
  }

  //** hàm click chuột phải vô folder để chỉnh sửa hoặc xóa */
  openRightFile(item, event) {
    event.stopPropagation()
    event.preventDefault()
    document.getElementById('element').click()
    this.activeLeft = item.name
    this.activeRight = item.name
  }

  //** truyền status true or false, để đóng chọn có dữ liệu hoặc kg có dữ liệu */
  selectFile() {
    if (this.activeSelect != "") {
      if (this.activeSelect.type == 1) {
        let data = { ...this.activeSelect }
        data.path = this.path
        this.dialogRef.close(data)
      } else alert("Vui lòng chọn file")
    } else alert("Vui lòng chọn file")
  }

  close() {
    let data = {
      path: this.path
    }
    this.dialogRef.close(data)
  }

  activeFile(item) {
    this.activeSelect = item
    this.activeRight = ""
    this.activeLeft = item.name
  }
  resetActive(event?) {
    if (event) event.preventDefault()
    this.activeLeft = ""
    this.activeRight = ""
  }

  backPath() {
    let list: any = [];
    let pathBack = ""
    if (this.path.length > 0) list = this.path.split("/");
    let arr = list.filter(item => item != "")
    arr.pop()
    for (let i in arr) pathBack += "/" + arr[i]
    this.getFilenames_onViethas(pathBack)
    this.path = pathBack
  }

  viewFilenames(value) {
    this.view = value
    localStorage.setItem("viewFilenames", JSON.stringify(this.view))
  }


  statusName: boolean = true
  statusType: boolean = true
  statusSize: boolean = true
  sort(status, value) {
    switch (value) {
      case 'name': {
        if (status) this.showFileNames = this.vhAlgorithm.sortVietnamesebyASC(this.fileNames, 'name')
        else this.showFileNames = this.vhAlgorithm.sortVietnamesebyDESC(this.fileNames, 'name')
      }
      case 'type': {
        if (status) this.showFileNames = this.vhAlgorithm.sortNumberbyASC(this.fileNames, 'type')
        else this.showFileNames = this.vhAlgorithm.sortNumberbyDESC(this.fileNames, 'type')
      }
      case 'size': {
        if (status) this.showFileNames = this.vhAlgorithm.sortNumberbyASC(this.fileNames, 'size')
        else this.showFileNames = this.vhAlgorithm.sortNumberbyDESC(this.fileNames, 'size')
      }
    }
  }

}
